form h5{
    color: #ffffff;
    font-size: 10px;
}

form h1{
    color: #ffffff;
}

.login-form{
    width: 100%;
    background-color: #EA7600;
    color: #ce6700;
}

#login_button{
    width: 500px;
    background-color: #00A499;
    border-color: #00A499;
}

#login{
    background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8) ), url("../assets/casa_central_2.jpg");
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    z-index: 1;
    text-align: center;
}