td {
    padding: 0 !important;
}

td span{
    min-height: 50%;
    height: 50%;
    padding: 15px 0;
    display: block;
    font-size: 20px;
    font-weight: bold;
    justify-content: center;
} 

#estadistico{
    background-color: #ffffff;
    text-align: center;
    
}
#estadistico:hover{
    background-color: #d8d8d8;
    font-weight: bold;
}