body {
  margin: 0;
  font-family: 'Bebas Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Bebas Neue', sans-serif;
}

.fila{
  padding: 0 !important;
  margin: 0 !important;
}

.columna{
  padding: 0 !important;
  margin: 0 !important;

}

/* *{
  font-family:Arial, Helvetica, sans-serif;
} */