#dias {
    width: max-content;
    height: 100%;
    background-color: #00477F;
    color: white;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    font-size: small;
    text-align: center;
}


.cModulos{

    white-space: pre-wrap;
    line-break: normal;
    word-break: break-word;
    word-wrap: break-word;

    text-align: center;
    width: 120px;
    min-width: 120px;
    font-size: small;
    font-style: normal;
    
    
}
#conSala{
    background-color: #c7f1c2;
    text-align: center;   
}
#conSala:hover{
    background-color: #a9e9a4;
    font-weight: bold;
}

td {
    padding: 0 !important;
}

td a{
    min-height: 100%;
    height: 100%;
    padding: 15px 0;
    display: block;
    justify-content: center;
    /* width: 100%; */
} 

#sinSala{
    background-color: #ffdfd0;
    text-align: center;
    
}
#sinSala:hover{
    background-color: #fcb7af;
    font-weight: bold;
}

#profesores {
    text-align: left;
    margin-left: 30px;
    font-size: 20px;
}

#colDia{
    width: 170px;
    min-width: 107px;
}

#fondo{

    justify-content: space-around;

}