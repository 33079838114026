

#inputGroupFile01{
    margin-top: 50px;
}


.profile-box {
    width: 100%;
    max-width: 100px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;

}

.dataP{
    margin-top: 50%;
    margin-left: 10%;
}

